import React, {useEffect, useRef, useState} from "react";
import FlowContainer from "../../../PickProgram/FlowElements/FlowContainer";
import CmsHelper, {PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import {useCms} from "../../../../context/CmsContext";
import GoogleAnalytics from "../../../../analytics/GoogleAnalytics";
import AwsApiService from "../../../../services/AwsApiService";
import {useAccount} from "../../../../context/AccountContext";
import AccountsService from "../../../../services/AccountsService";
import {useNavigate} from "react-router-dom";
import GenericError from "../../../Utilities/GenericError";
import PopUp from "../../../Utilities/PopUp";
import {Link} from "@mui/material";
import UserService from "../../../../services/UserService";

export default function MigrationFlow({packages, couponExists, handleNextStep,
                                          contestSession,
                                          promoTargetDisplayName, promoRedirectURL,
                                          flow,
                                          cancellationFlow,
                                      token}) {
    const account = useAccount();
    const {pickProgramData} = useCms();

    const navigate = useNavigate();

    const [products, setProducts] = useState([])

    const [unableToUpgrade, setUnableToUpgrade] = useState(false);

    useEffect(()=>{
        setLoading(true);
        let products;

        if(!!couponExists || !!token){
            products = packages ?? []
        }else{
            if(packages.displayName !== "Lite" && contestSession){
                handleNextStep();
                navigate("/CongratsPage")
                return;
            }
            products = [...packages.upgrades, ...(cancellationFlow ? [] : packages.downgrades)];
            products = products.filter(product => product.displayName !== "Lite"); //removes lite products from upgrade.

            if (!!promoTargetDisplayName) {
                const targetDisplayNames = JSON.parse(promoTargetDisplayName);
                products = products.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
                if(products.length === 0){
                    window.open(promoRedirectURL, "_self")
                    return;
                }
            }
        }

        if(products.length === 0){
            setUnableToUpgrade(true);
            account.clearSessionCoupon();
            setLoading(false);
            return;
        }

        setLoading(false);

        setProducts(products);

    }, [packages, couponExists, contestSession, promoTargetDisplayName, handleNextStep, promoRedirectURL]) //eslint-disable-line react-hooks/exhaustive-deps

    const [iframeUrl, setIframeUrl] = useState(undefined);
    const formRef = useRef(null)

    const [loading, setLoading] = useState(false);
    const onNext = (product, setCardInUse, setError) =>{
        setLoading(true);

        let request = {
            pricePoint: product.pricePoint,
        };

        if (!!couponExists) {
            request = {
                ...request,
                couponCode: couponExists,
            };
        }

        if(!!product?.promotions){
            request = {
                ...request,
                promotionId: product?.promotions?.[0],
            };
        }

        account.setChosenProgramName(
            products.find((obj) => obj.productId === product.productId).name
        );
        GoogleAnalytics.onSelectPackage();
        AwsApiService.onPickProgram();


        sessionStorage.removeItem("selectedProgramId");

        setLoading(true)

        AccountsService.upgradeSubscription({...request, flow: flow})
            .then((response) => {
                if (sessionStorage.getItem("discount-coupon")) {
                    sessionStorage.removeItem("discount-coupon");
                }

                handleNextStep(true);

                setIframeUrl(new URL(response.data.uri));
            })
            .catch((err) => {
                //TODO handle error
            })
            .finally(()=>{
                setLoading(false)
            });
    }

    useEffect(() => {
        const handleMessage = (event)=>{
            if (!iframeUrl || event.origin !== iframeUrl?.origin) {
                return;
            }

            let data;
            try {
                data = JSON.parse(event.data);
            }catch (e){
                data = event.data;
            }
            if((parseInt(data?.status) === 1)){
                console.log("Successful flow")

                handleNextStep(true);
                if(!!couponExists){
                    sessionStorage.setItem("coupon-success", true)
                }
                if (UserService.isSubscriptionAction()) {
                    navigate("/CongratsPage");
                } else {
                    navigate("/CongratsPage");
                }
            }
        }

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [iframeUrl]);  //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (formRef.current && iframeUrl) {
            formRef.current.submit()
        }
    }, [iframeUrl, formRef])

    return <>
    {iframeUrl ?
        <div style={{flex: 1, display: "flex"}}>
            <form hidden={true}
                  target={"ev-iframe"}
                  action={!!iframeUrl ? iframeUrl?.origin + iframeUrl?.pathname : ""}
                  method={"POST"} ref={(reference) => formRef.current = reference}>
                {iframeUrl && iframeUrl.search
                    .replace("?", "")
                    .split("&")
                    .map((token) => {
                        const tokens = token.split("=")
                        return <input key={tokens[0]} name={tokens[0]} defaultValue={tokens[1]}/>
                    })}
            </form>
            <iframe name={"ev-iframe"} title={"ev-checkout"} src={iframeUrl}
                    style={{width: '100%', height: '100vh', border: 'none'}}
            />
        </div>:
        <>
            <FlowContainer packages={products}
                           loading={loading}
                           update={true}
                           currentProgram={!!packages?.displayName && !!packages?.productId ? {displayName: packages.displayName, productId: packages.productId} : undefined}
                           cancellationFlow={cancellationFlow}
                           couponExists={!!couponExists}
                           contestState={contestSession}
                           cmsData={PickProgramPageHelper.getPrograms(pickProgramData, cancellationFlow ? CmsHelper.CANCELLATION_MIGRATE : CmsHelper.UPGRADE_PROGRAM)}
                           onNext={onNext}
            />
            <PopUp
                openPopUp={unableToUpgrade}
                setOpenPopUp={(can)=>{setUnableToUpgrade(!can)}}
                maxWidth="md"
                showCloseButton={false}
                outlined={false}
                error={true}
                closeWhenClickedOutside={false}>
                <GenericError
                    setClose={setUnableToUpgrade}
                    buttonText={"Επιστροφή"}
                    executeFunction={function () {
                        window.open(`${process.env.REACT_APP_CONGRATS_BUTTON_LINK}`, "_self");
                    }}
                    mainText={"Δεν υπάρχει προσφορά συμβατή με το πακέτο σου αυτή τη στιγμή."}
                    showIcon={false}>
                    <span className={"pick-program-fallback-modal"}>Για περισσότερες πληροφορίες επισκέψου το <Link href={"https://help.antennaplus.gr/"} target={"_blank"}>Κέντρο Βοήθειας</Link>.</span>
                </GenericError>
            </PopUp>
        </>
    }
    </>
}