import "./FlowContainer.css"
import Banner from "../Banner/Banner";
import React, {useEffect, useRef, useState} from "react";
import {useCms} from "../../../context/CmsContext";
import ProductContainer from "../ProductContainer/ProductContainer";
import CmsHelper, {PickProgramPageHelper} from "../../Utilities/CmsHelpers";
import GenericError from "../../Utilities/GenericError";
import PopUp from "../../Utilities/PopUp";
import {Button} from "@mui/material";
import CurrentProductContainer from "../ProductContainer/CurrentProductContainer";
import Loader from "../../Loader/Loader";
import {useAuth} from "../../../context/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useAccount} from "../../../context/AccountContext";

export default function FlowContainer({children, packages, update, couponExists, contestState, cmsData, onNext, loading, currentProgram, cancellationFlow}) {
    const account = useAccount();
    const { setCheckLoggedIn } = useAuth();
    const navigate = useNavigate();
    let location = useLocation();
    const unsubscribeFlow = location.state ?? {};

    const [products, setProducts] = useState([]);

    const {renderingType, productBannersData, pickProgramData  } = useCms()

    const [isMultiMonthly, setIsMultiMonthly] = useState(true);


    const [cmsPrograms] = useState(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.DEFAULT));

    const programInfo = PickProgramPageHelper.getProgramInfo(cmsPrograms);

    const orderMap = programInfo.programData.reduce((map, item, index) => { //creates an order map to be used to sort compatibility matrix array
        map[item?.displayName?.value] = index;
        return map;
    }, {});

    useEffect(()=>{
        if(!!cmsPrograms && !!packages.length){
            let packagesWithFreeTrials = structuredClone(packages);
            packagesWithFreeTrials.forEach(element => {
                if(element?.promotions !== null && element?.promotions !== undefined)
                    element.displayName = element?.displayName + "::" + element?.promotions?.[0];
            });
            setProducts(packagesWithFreeTrials
                .filter(program => cmsPrograms.programs.some(cmsProgram => program?.displayName === cmsProgram?.displayName?.value && cmsProgram.isMultiMonthly === isMultiMonthly))
                .sort((a, b) => {
                    return orderMap[a?.displayName] - orderMap[b?.displayName];
                })
            )
        }
    }, [packages, cmsPrograms, isMultiMonthly]) //eslint-disable-line react-hooks/exhaustive-deps

    const [selectedProduct, setSelectedProduct] = useState(!!sessionStorage.getItem("selectedProgramId") ? JSON.parse(sessionStorage.getItem("selectedProgramId")) : undefined);

    const [error, setError] = useState(false);
    const [cardInUse, setCardInUse] = useState(false);

    const [cardInUseAction, setCardInUseAction] = useState(()=>{})

    const [cardInUseChangeAction, setCardInUseChangeAction] = useState(()=>{})

    const handleTemp = useRef();


    const [tempState, setTempState] = useState();

    const handleResize = () => {
        if(handleTemp.current && !!handleTemp.current.offsetWidth){
            new Promise(resolve =>{setTimeout(()=> {
                setTempState(handleTemp.current.offsetWidth)
            }, 10)})//gives it some time to load the dom
        }else{
            new Promise(resolve =>{setTimeout(()=>handleResize(), 100)})//gives it some time to load the dom
        }
    };

    useEffect(()=>{
        handleResize()
    },[isMultiMonthly]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const [switchExists, setSwitchExists] = useState(true);

    useEffect(()=>{
        const withoutFree = packages.filter(program => program?.displayName !== "Lite");
        if(!!withoutFree.length){
            if(withoutFree
                .every(program => cmsPrograms.programs.some(cmsProgram => program?.displayName === cmsProgram?.displayName?.value && cmsProgram.isMultiMonthly))){
                setIsMultiMonthly(true);
                setSwitchExists(false);
            }else if(withoutFree
                .every(program => cmsPrograms.programs.some(cmsProgram => program?.displayName === cmsProgram?.displayName?.value && !cmsProgram.isMultiMonthly))){
                setIsMultiMonthly(false)
                setSwitchExists(false)
            }
        }
    }, [packages, cmsPrograms])

    return ((products.length > 0 && !!productBannersData) &&
        <>
            {loading ? <Loader /> :
                <div className={"pickprogram-wrapper"}>
                    <div className={"pickprogram-upper"}>
                        <Banner bannerData={productBannersData}
                                displayName={packages[0]?.displayName}
                                deviceType={renderingType} update={update}/>
                        <div className={"pickprogram-header-wrapper"}>
                            <div className={"pickprogram-header"}>
                                <span className={"pickprogram-header-title"}>
                                    {(!!couponExists) ?
                                        "Απόλαυσε με τον κωδικό προσφοράς"
                                        :
                                        contestState ?
                                            update ?
                                                "Αναβάθμισε το πρόγραμμά σου για να πάρεις μέρος στον διαγωνισμό!"
                                                :
                                                "Επίλεξε το πρόγραμμά σου για να πάρεις μέρος στον διαγωνισμό!"
                                            :
                                            cmsData?.title
                                    }
                                </span>
                                {(!(!!couponExists) && !!cmsData?.details) && (
                                    <span className={"pickprogram-header-subtitle"}>{cmsData?.details}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {(switchExists && !(!!couponExists)) &&
                        <div className={"pickprogram-header-switch"}>
                            <div className={`pickprogram-header-switch-selected ${isMultiMonthly ? "multy" : "monthly"}`} />
                            <button onClick={setIsMultiMonthly.bind(null, true)}>πολύμηνη συνδρομή</button>
                            <button onClick={setIsMultiMonthly.bind(null, false)}>μηνιαία συνδρομή</button>
                        </div>
                    }
                    <div ref={handleTemp} className={`pickprogram-content ${products.length % 2 === 0 ? "even" : "odd"} ${products.length < 2 ? "single" : ""}`}>
                        {products
                            .map((product, index) => {
                            return (
                                <ProductContainer key={index}
                                                  product={product}
                                                  isMultiMonthly={isMultiMonthly}
                                                  isSelected={selectedProduct?.productId === product.productId && selectedProduct?.pricePoint === product.pricePoint}
                                                  setSelected={setSelectedProduct.bind(null, product)}
                                                  cancellationFlow={cancellationFlow}
                                                  onNext={onNext.bind(null, product, (state, action, changeAction)=>{
                                                      setCardInUse(state)
                                                      setCardInUseAction(()=>action);
                                                      setCardInUseChangeAction(()=>changeAction)
                                                  }, setError)}
                                />
                            )
                        })}
                    </div>
                    {((!!currentProgram && currentProgram?.displayName !== "Lite" && PickProgramPageHelper.programExists(cmsPrograms, currentProgram?.displayName)) && !(!!couponExists) && !cancellationFlow) &&
                        <div className={`pick-program-current-program ${products.length % 2 === 0 ? "even" : "odd"}`} style={{width: tempState+"px"}}>
                            <CurrentProductContainer product={currentProgram} />
                        </div>
                    }

                    {cancellationFlow &&
                        <div className={"cancellation-ctas"}>
                            <button className={"cancellation-primary"} onClick={onNext.bind(null, products[0], (state, action, changeAction)=>{
                                setCardInUse(state)
                                setCardInUseAction(()=>action);
                                setCardInUseChangeAction(()=>changeAction);
                            }, setError)}>Το θέλω!</button>
                            <button className={"cancellation-secondary"} onClick={() => {
                                setCheckLoggedIn(false);
                                navigate("/Unsubscribe", {state: unsubscribeFlow});
                            }}>Δεν ενδιαφέρομαι</button>
                        </div>
                    }


                    <div className={`pick-program-current-program disclaimers ${products.length % 2 === 0 ? "even" : "odd"}`} style={{width: tempState+"px", alignSelf:"center"}}>
                        {products.filter(product => product?.displayName !== "Lite").slice(0,1)
                            .map((product, index) => {
                                const cmsProgramDisclaimers = PickProgramPageHelper.getProgramDisclaimers(programInfo, product?.displayName);
                                return (cmsProgramDisclaimers?.value.map((disclaimer, index) => <span key={index} style={{fontSize: "14px", fontWeight:"300"}}>{disclaimer.value}</span>))
                            })
                        }
                    </div>
                </div>
            }
            <PopUp
                openPopUp={error}
                setOpenPopUp={setError}
                maxWidth="md"
                showCloseButton={false}
                outlined={false}
                error={true}
                closeWhenClickedOutside={false}
            >
                <GenericError
                    setClose={setError}
                    mainText={"Υπήρξε κάποιο πρόβλημα στην επιλογή προγράμματος"}
                    secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
                />
            </PopUp>
            <PopUp
                openPopUp={cardInUse}
                setOpenPopUp={setCardInUse}
                maxWidth="md"
                showCloseButton={false}
                outlined={false}
                error={true}
            >
                <GenericError
                    setClose={setCardInUse}
                    success={true}
                    mainText={"Η κάρτα έχει χρησιμοποιηθεί ξανά!"}
                    secondaryText={"Η δωρεάν δοκιμή 7 ημερών δεν είναι διαθέσιμη."}
                    showIcon={false}
                    hideButtons={true}
                />
                <div id="card-in-use-actions">
                    <Button
                        variant="text"
                        color="primary"
                        className="grey"
                        onClick={() => {
                            setCardInUse(false);
                            cardInUseChangeAction();
                            account.setChargifyAccess(true);
                            navigate("/ChargifyForm");
                        }}>
                        Αλλαγή κάρτας
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>{setCardInUse(false); cardInUseAction()}}>
                        Συμφωνώ & επιθυμώ να συνεχίσω
                    </Button>
                </div>
            </PopUp>
        </>
    );
}
