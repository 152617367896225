import {PaymentProvider} from "../PaymentProvider.ts";
import EvergentForm from "../../components/Evergent/EvergentForm/EvergentForm"
import RegistrationFlow from "../../components/Evergent/PickProgram/FlowElements/RegistrationFlow"
import MigrationFlow from "../../components/Evergent/PickProgram/FlowElements/MigrationFlow"

export class EvergentPaymentProvider implements PaymentProvider {
    paymentForm= "/EvergentForm";
    PaymentProviderComponent= <EvergentForm />
    RegistrationFlow= RegistrationFlow
    MigrationFlow= MigrationFlow;
}