import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function AddCoupon() {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("discount-coupon")) {
      navigate("/PickProgram");
    } else {
      window.location.assign(
        `${process.env.REACT_APP_COUPON_UPGRADE_FREE_REDIRECT}`,
        "_self"
      );
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}

export default AddCoupon;
