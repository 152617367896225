import axios from "axios";
import {
  baseUrl,
  // getOptions,
  // getOptionsForAction,
  postOptions,
  // putOptions,
  // deleteOptions,
  // patchOptions, patchOptionsForAction,
} from "../config.js";
class InvoicesService {
  constructor(baseUrl) {
    this.basePath = "/invoices";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }
  
  downloadPDF(data){
    return fetch(`${this.baseUrl}${"/pdf"}`, {
      method: "POST",
      headers: {
        "Accept": "application/pdf",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${
          JSON.parse(sessionStorage.getItem("kc_tok"))
            ? JSON.parse(sessionStorage.getItem("kc_tok")).token
            : JSON.parse(sessionStorage.getItem("kc_action")).token
        }`,
      },
      body: JSON.stringify(data)
    })
  }
}

export default new InvoicesService(baseUrl); //eslint-disable-line import/no-anonymous-default-export