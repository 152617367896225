import {EvergentPaymentProvider} from "./Evergent/EvergentPaymentProvider.tsx";
import {ChargifyPaymentProvider} from "./Chargify/ChargifyPaymentProvider.tsx";

export interface PaymentProvider {
    paymentForm: string;
    PaymentProviderComponent: React.ReactNode;
    RegistrationFlow: React.FC;
    MigrationFlow: React.FC;
}

export default function usePaymentProvider(): PaymentProvider {
    const serviceType = process.env.REACT_APP_PROVIDER;

    if (serviceType === "EVERGENT") {
        return new EvergentPaymentProvider();
    } else {
        return new ChargifyPaymentProvider();
    }
}