import React, {useState, useEffect} from "react";
import {Button} from "@mui/material";
import DownloadApps from "../DownloadApps/DownloadsApps";
import "../GlobalStyles.css";
import "./CongratsPage.css";
import Loader from "../Loader/Loader";
import {useTranslation} from "react-i18next";
// import AccountsService from "../../services/AccountsService";
import UserService from "../../services/UserService";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import AwsApiService from "../../services/AwsApiService";
import ContestService from "../../services/ContestService";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

function CongratsPage() {
    let account = useAccount();
    const {t} = useTranslation();

    const {setCheckLoggedIn} = useAuth();

    const mediaMatch = window.matchMedia("(min-width: 600px)");
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [resolveActionIframe, setResolveActionIframe] = useState(!!sessionStorage.getItem("kc_resolve_token"));
    const [resolveActionUrl, setResolveActionUrl] = useState("");

    useEffect(() => {
        const handler = (e) => {
            setMatches(e.matches);
        };
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });

    const [contest, setContest] = useState();
    // const [constestUpgade, setContestUpgrade] = useState(false);

    const [buttonRedirect, setButtonRedirect] = useState();

    const {isLoading, removeLoading} = useLoading()

    useEffect(() => { //nothing needs to wait loading to do these actions
        if (
            sessionStorage.getItem("kc_resolve_token") ||
            sessionStorage.getItem("kc_tok")
        ) {
            GoogleAnalytics.onCongratsLoad(account.chosenProgramName);
            GoogleAnalytics.onProductConversion(account.analyticsProductConversion);
            AwsApiService.onWelcome(account.chosenProgramName);
        }

        if (!!sessionStorage.getItem("kc_resolve_token")) {
            //case new user
            // console.log("new user")
            setResolveActionIframe(true)
            let actionToken = JSON.parse(sessionStorage.getItem("kc_action"))?.token
            let redirectUri = JSON.parse(atob(actionToken.split(".")?.[1]))?.["redirect_uri"];
            setButtonRedirect(redirectUri);
        } else {
            //case existing user
            setButtonRedirect(process.env.REACT_APP_CONGRATS_BUTTON_LINK)
        }

        //both user actions
        if (sessionStorage.getItem("coupon-success")) {
            //case coupon used
            sessionStorage.removeItem("coupon-success");
            setButtonRedirect(process.env.REACT_APP_COUPON_UPGRADE_FREE_REDIRECT)
        }

        if (sessionStorage.getItem("congratsRedirectURL")) {
            //if a promo session is active
            const temp = sessionStorage.getItem("congratsRedirectURL")
            // console.log(temp)
            sessionStorage.removeItem("congratsRedirectURL")
            setButtonRedirect(temp)
        } else if (sessionStorage.getItem("contestSession")) {
            //if a contest session is active
            setContest(sessionStorage.getItem("contestSession"))
            ContestService.entryContest({contestId: sessionStorage.getItem("contestSession")})
                .then(response => {
                })
                .catch(error => {
                    console.error(error);
                })
            setButtonRedirect(sessionStorage.getItem("contestRedirectURL"))
            sessionStorage.removeItem("contestSession");
            sessionStorage.removeItem("isContestUpgrade")
            sessionStorage.removeItem("contestRedirectURL")
        }
    }, []);

    useEffect(() => {//this page is not interestead in loading, if we are here we are here.
        if (isLoading("INIT")) {
            removeLoading("INIT")
        }
        // eslint-disable-next-line
    }, [isLoading]);


    useEffect(() => {
        if (resolveActionIframe) {
            try {
                let kcAction = JSON.parse(sessionStorage.getItem("kc_action"));
                let token = sessionStorage.getItem("kc_resolve_token");
                let redirectBack = new URL(kcAction?.challenge.replace("%7Bchallenge%7D", token));
                sessionStorage.removeItem("kc_action");
                sessionStorage.removeItem("kc_resolve_token");
                setResolveActionUrl(redirectBack.toString());
            } catch (e) {
                console.log("Cannot parse kc_action resolution URL")
            }
        }
    }, [resolveActionIframe])
    // useEffect(() => {
    //   AccountsService.getProfile()
    //     .then((response) => {
    //       account.setUserData(response.data);
    //       account.setLoggedIn(true);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       //console.log("errοr");
    //       setLoading(false);
    //     });
    // }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const onStart = () => {
        setCheckLoggedIn(false);
        window.location.replace(buttonRedirect ?? "/")
        //window.open(`${process.env.REACT_APP_CONGRATS_BUTTON_LINK}`, "_self");
    };

    return (
        <>
            {isLoading() ? (
                <Loader/>
            ) : (
                <div className="congrats-page-wrapper">
                    {resolveActionIframe && resolveActionUrl.length > 0 &&
                        <iframe hidden={true} title={"resolution-iframe"} src={resolveActionUrl ?? ""}></iframe>}
                    <div className="congrats-page box">
                        <div className="main-message">
                            {(contest && !!sessionStorage.getItem("kc_resolve_token")) ?
                                <div className={"contestTitle"}>
                                    <p>Συγχαρητήρια! Με την επιτυχημένη εγγραφή σου έλαβες μέρος στον διαγωνισμό!</p>
                                </div>
                                :
                                contest ?
                                    <div className={"contestTitle"}>
                                        <p>Συγχαρητήρια έλαβες μέρος στον διαγωνισμό!</p>
                                    </div>
                                    :
                                    <div className={"contestTitle"}>
                                        {!!sessionStorage.getItem("kc_resolve_token") ?
                                            <p>{t("congratsInfo")}</p>
                                            :
                                            <p>Η αλλαγή προγράμματος καταχωρήθηκε!</p>
                                        }
                                    </div>
                            }
                        </div>
                        <div className={"congrats-apps"}>
                            <DownloadApps
                                text={
                                    !matches
                                        ? t("congratsAppTextMobile")
                                        : t("congratsAppTextDesktop")
                                }
                                desktopHidden={false}
                            />
                            <div className="ant1-tv-app">
                                <img src="images/tv-outline.png" alt="tv icon"/>
                                <div className="message">
                                    <div className="main">
                                        {t("dowmloadonTV1")}
                                        <a href="/Link">{t("dowmloadonTVLink")}</a>
                                        {t("dowmloadonTV2")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="congrats-banner" className={`${contest ? "contest" : ""}`}>
                        <div className="button-wrapper">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onStart}
                                id="congrats-button"
                            >
                                {t("congratsButtonText")}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CongratsPage;
