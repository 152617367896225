import Keycloak from "keycloak-js";

var keycloak = new Keycloak(process.env.REACT_APP_KEYCLOAK_JSON);

var userInfo = { email: "" };

function initKeycloak(setLoggedIn, setAuthz) {
  keycloak = new Keycloak(process.env.REACT_APP_KEYCLOAK_JSON);
  let userTokens = getUserTokens();
  let initOptions = !!userTokens
    ? userTokens
    : {
        onLoad: "check-sso",
        checkLoginIframe: true,
        responseMode: "query",
        pkceMethod: false,
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      };
  setAuthorizing(true, setAuthz);
  return keycloak.init(initOptions)
}

function doLogin(redirect_uri, language) {
  keycloak.login({ redirectUri: redirect_uri, locale: language });
}

function doRegister(redurect_uri, language) {
  if (!isLoggedIn()) {
    keycloak.register({
      redirectUri: redurect_uri,
      locale: language,
    });
  }
}

function doLogout() {
  localStorage.removeItem("kc_tok");
  sessionStorage.removeItem("kc_tok");
  // console.log(keycloak)
  keycloak.logout();
}

function isLoggedIn() {
  return keycloak.authenticated;
}

function updateUserTokens() {
  return keycloak
    .updateToken()
    .then(() => {
      //console.log("successfully get a new token", keycloak.token);
      localStorage.setItem(
        "kc_tok",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      );
      sessionStorage.setItem(
        "kc_tok",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      );
      userInfo = keycloak.tokenParsed;
    })
    .catch(() => {
      doLogout();
      doLogin(window.location.href, "gr");
    });
}

function getToken() {
  return keycloak.token;
}

function getUserTokens() {
  //let tokens = localStorage.getItem("kc_tok");
  let tokens = sessionStorage.getItem("kc_tok");
  if (!!tokens) {
    let userTokens = JSON.parse(tokens);
    userTokens.onLoad = "check-sso";
    userTokens.checkLoginIframe = true;
    userTokens.responseMode = "query";
    userTokens.pkceMethod = false
    userTokens.silentCheckSsoRedirectUri =
      window.location.origin + "/silent-check-sso.html";
    return userTokens;
  }
  return false;
}

function getUser(setLoggedIn) {
  return userInfo;
}

function clearUserTokens() {
  localStorage.removeItem("kc_tok");
  sessionStorage.removeItem("kc_tok");
}

function updatePassword(language) {
  let url = window.location.origin;
  keycloak.login({
    redirectUri: url + "/PasswordUpdate",
    locale: language,
    action: "UPDATE_PASSWORD",
  });
}

function updateProfile(language) {
  let url = window.location.origin;
  keycloak.login({
    redirectUri: url + "/Account",
    locale: language,
    action: "UPDATE_PROFILE",
  });
}

keycloak.onTokenExpired = () => {
  //console.log("token expired", keycloak.token);
  // console.log("token expired");
  keycloak
    .updateToken()
    .then(() => {
      //console.log("successfully get a new token", keycloak.token);
      // console.log("successfully get a new token");
      sessionStorage.setItem(
        "kc_tok",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      );
    })
    .catch(() => {
      console.log("error updating token");
    });
};

function isAuthorizing() {
  return !!sessionStorage.getItem("kc_loading");
}

function setAuthorizing(auth, fn) {
  if (auth) sessionStorage.setItem("kc_loading", true);
  else sessionStorage.removeItem("kc_loading");
  fn(auth);
}

function isSubscriptionAction() {
  let searchParams = new URLSearchParams(window.location.search);
  let challengeUrl = searchParams.get("action_challenge");
  if (!!challengeUrl) {
    challengeUrl = new URL(challengeUrl);
    let challengeSearchParams = new URLSearchParams(challengeUrl.search);
    let actionTokenString = challengeSearchParams.get("key");

    sessionStorage.setItem(
      "kc_action",
      JSON.stringify({
        challenge: searchParams.get("action_challenge"),
        token: actionTokenString,
        client_id: challengeSearchParams.get("client_id"),
      })
    );
  }
  let kcActionString = sessionStorage.getItem("kc_action");
  if (!!kcActionString) {
    let kcAction = JSON.parse(kcActionString);
    let token = parseJwt(kcAction.token);
    if (hasJwtExpired(token)) {
      clearActionToken();
      return false;
    }
    return true;
  }
  return false;
}

function resolveSubscriptionAction(token, sessionRedirect = true, redirectURL) {
  let kcAction = JSON.parse(sessionStorage.getItem("kc_action"));
  sessionStorage.removeItem("kc_action");
  sessionStorage.removeItem("kc_resolve_token");
  if(!!token){
      let redirectBack = new URL(kcAction?.challenge.replace("%7Bchallenge%7D", token));
      window.location.replace(`${redirectBack}`);
  }else{
      if(!!sessionRedirect && !!redirectURL){
          window.location.replace(`${redirectURL}`);
      }
  }
}

function clearActionToken() {
  sessionStorage.removeItem("kc_action");
}

function hasJwtExpired(token) {
  return calculateExpiresInSeconds(token) < 0;
}

function calculateExpiresInSeconds(token) {
  return token.exp - new Date().getTime() / 1000;
}

// function calculateExpiresInMillis(token) {
//   return token.exp * 1000 - new Date().getTime();
// }

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const UserService = {
  initKeycloak,
  isAuthorizing,
  setAuthorizing,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUserTokens,
  clearUserTokens,
  updateUserTokens,
  doRegister,
  getUser,
  updatePassword,
  updateProfile,
  isSubscriptionAction,
  resolveSubscriptionAction,
  clearActionToken,
};

export default UserService;
